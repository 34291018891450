

























import { defineComponent, ref } from "@vue/composition-api";
import { User } from "@/store";

interface Props {
  user: User;
}

export default defineComponent({
  name: "ResendCredentials",
  props: {
    user: Object,
    loading: Boolean,
  },
  setup(props: Props, { emit }) {
    const displayDialog = ref<boolean>(false);

    function submit() {
      emit("submit", { user: props.user });
      displayDialog.value = false;
    }

    return {
      displayDialog,
      submit,
    };
  },
});
