



















































import {
  defineComponent,
  computed,
  onBeforeMount,
  watch,
} from "@vue/composition-api";
import { Role, UserUpdateBody, User } from "@/store";
import UserForm from "@/components/forms/User.vue";
import {
  useStore,
  useUserService,
  useRoleService,
  useRouter,
} from "@/composables/providers";
import { UserActions, UserGetters } from "@/store/user";
import { RoleGetters, RoleActions } from "../../store/role";
import { SessionGetters } from "../../store/session/index";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import ResendCredentialsDialog from "@/components/dialogs/ResendCredentials.vue";

export default defineComponent({
  name: "UsersUpdate",
  components: {
    UserForm,
    ConfirmationDialog,
    ResendCredentialsDialog,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userService = useUserService();
    const roleService = useRoleService();

    const isSiteAdmin = computed<boolean>(() =>
      store.getters[SessionGetters.ROLE] === "site admin" ? true : false
    );
    const isOrganizationAdmin = computed<boolean>(() =>
      store.getters[SessionGetters.ROLE] === "organization admin" ? true : false
    );

    const userIsSiteAdmin = computed<boolean>(() => {
      const userRole = store.getters[UserGetters.SELECTED_USER_ROLE];
      if (userRole === "site admin") {
        return true;
      }
      return false;
    });

    const isEditable = computed<boolean>(() => {
      if (
        isSiteAdmin.value ||
        (isOrganizationAdmin.value && !userIsSiteAdmin.value)
      ) {
        return true;
      } else return false;
    });

    const roles = computed<Role[]>(() => {
      const displayRoles = store.getters[RoleGetters.ROLES];
      if (!isSiteAdmin.value && !userIsSiteAdmin.value) {
        return displayRoles.filter((role: Role) => role.id > 1);
      } else {
        return displayRoles;
      }
    });

    const id = computed<string>(() => router.currentRoute.params["id"] || "");

    async function submit(body: UserUpdateBody) {
      await store.dispatch(UserActions.UPDATE, {
        id: id.value,
        body,
        service: userService,
      });
    }

    const usersLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const rolesLoading = computed<boolean>(
      () => store.getters[RoleGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => usersLoading.value || rolesLoading.value
    );
    const selected = computed<User | undefined>(
      () => store.getters[UserGetters.SELECTED]
    );
    const action = computed<string>(() =>
      selected.value && selected.value.deleted ? "Reactivate" : "Delete"
    );

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(RoleActions.LIST, { service: roleService }),
        store.dispatch(UserActions.SELECT, {
          id: id.value,
          service: userService,
        }),
      ]);
    });

    watch(id, async (newVal) => {
      await store.dispatch(UserActions.SELECT, {
        id: newVal,
        service: userService,
      });
    });

    //ToDo implement with MFA for users or delete
    // const mfaEnabled = computed<boolean>(() => {
    //   if (selected.value) {
    //     return selected.value.mfaEnabled;
    //   } else {
    //     return false;
    //   }
    // });
    // const mfaChipColor = computed<string>(() =>
    //   mfaEnabled.value ? "green" : "grey"
    // );
    // const mfaChipText = computed<string>(() =>
    //   mfaEnabled.value ? "MFA Enabled" : "MFA Disabled"
    // );

    async function resendUserPassword($event: { user: User }) {
      await store.dispatch(UserActions.RESEND_CREDENTIALS, {
        id: $event.user.id,
        service: userService,
      });
    }

    async function deleteUser() {
      await store.dispatch(UserActions.DELETE, {
        service: userService,
        id: id.value,
      });
      router.push({ name: "UserList" });
    }

    async function reactivateUser() {
      await store.dispatch(UserActions.REACTIVATE, {
        service: userService,
        id: id.value,
      });
      router.push({ name: "UserList" });
    }

    return {
      roles,
      submit,
      loading,
      selected,
      deleteUser,
      isSiteAdmin,
      resendUserPassword,
      reactivateUser,
      action,
      isEditable,
    };
  },
});
